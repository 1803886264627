import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ReactNotification from "react-notifications-component";
// import SideBar from "~components/sidebar";
// import Intercom from "react-intercom";
import AuthRoute from "../../components/auth-route";
import routes from "../../routes";
import { useSelector } from "react-redux";
import { titleManager } from "~utils/titleManager";
import "./index.less";
import "react-notifications-component/dist/theme.css";
import PublicRoutes from "~components/public-routes";
import smartlookClient from "smartlook-client";
// import NewFooter from "~components/footer/NewFooter";
// import HelpCrunch from "~components/helpcrunch";
import { CustomerlyProvider } from "react-live-chat-customerly";
import CustomerlyChat from "~components/customerly";

function App() {
  const smartLookKey = process.env.REACT_APP_SMARTLOOK_KEY;
  const smartLookRegion = process.env.REACT_APP_SMARTLOOK_REGION;
  const customerlyId = process.env.REACT_APP_CUSTOMERLY_ID;
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  const userDetails = useSelector((state: any) => state.comapanyMemberDetail);
  let user = null;
  user = {
    user_id: userDetails && userDetails.userId,
    email: userDetails && userDetails.email,
    name: userDetails && userDetails.name,
  };
  const location = useLocation();
  useEffect(() => {
    titleManager(location);
  }, [location]);
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
  const list = [
    "LifeQuestionnaire",
    "LifeSummary",
    "LifeThankYou",
    "LifeResults",
    "LifeConfirmation",
  ];
  useEffect(() => {
    if (process.env.REACT_APP_HP_URL == "https://wallet-empresas.mudey.pt/") {
      smartlookClient.init(smartLookKey, {
        region: smartLookRegion,
      });
    }
  }, []);

  const redirectUrlsTo = (path) => {
    switch (path) {
      case "/parceria-sodexo":
        return <Redirect to="/parceria-pluxee" />;
      case "/parceria-sodexo/seguro-vida-empresas":
        return <Redirect to="/parceria-pluxee/seguro-vida-empresas" />;
      default:
        return <Redirect to="/not-found" />;
    }
  };
  return (
    <>
      <ReactNotification />

      <Suspense fallback={<React.Fragment />}>
        <Switch>
          {isAuthenticated ? (
            <Redirect exact from="/" to="/dados-empresa" />
          ) : null}
          {routes.map((route: Object, index: number) => {
            const authRequired =
              list.includes(route.name) && isAuthenticated
                ? true
                : route.authRequired;
            if (authRequired) {
              return (
                <AuthRoute
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  name={route.name}
                  path={route.path}
                  title={route.title}
                  notAccess={route.notAccess}
                />
              );
            } else {
              return (
                <PublicRoutes
                  component={route.component}
                  exact={route.exact}
                  key={index}
                  name={route.name}
                  path={route.path}
                  title={route.title}
                />
              );
            }
          })}

          {redirectUrlsTo(location.pathname)}
        </Switch>
        {location.pathname !==
          "/seguro-vida-empresas/iframe-redirect-success" && (
          // <Intercom
          //   appID={intercomAppId}
          // />
          // <HelpCrunch userData={user} />
          <CustomerlyProvider appId={customerlyId}>
            <CustomerlyChat userData={user} />
          </CustomerlyProvider>
        )}
      </Suspense>
    </>
  );
}
export default App;

//DO NOT DELETE
//.env.stg.enc
// U2FsdGVkX1+QjapFhBlh3pMQ6rJsx2BmJDF5iNZWUWQE+mkRDa+tJMpE7+hCQoY3
// BizPFvfuyX4H6hMFAf7+Jiiyvtg1LlDw/txS0iXX0bq3URl6ffqnOgtSYHIrv46v
// VwB/SJyvW3XEc1k/jpMGdM9Tt1BPfktZ6e6NXqgmDJnc7IDK1yX0/JcJvxTZSExm
// njMA1tH1Arbv0JcibwqQFmsGtcq8EhjvSZDvjnRtR8aZI8oWYG5W4oBL8xEuIWVH
// 8gMICc9qHTxXxiY3FHL1RC+aGpRB8HwKpWVbkFluDG1WVe9JJ7xgmA/knF6jOG5W
// f76HRqxbpGhVhuExkMkTPqswdhtVPDrb3iVMy5h/78hpOiNKCUYWbDCjAQ1linC1
// iX+ycyYv3jegNjXETpBwOFoiP731ptH+uMaHB2m+JEqk1lB9Nr+mHpaw2uSYg9Es
// OZzmpYnO8rH8xFKOVGgvfFVNIOA+mnreCRLzAj883M8cyxp+6n6Z2LkiOui7gwla
// CExxZz41lMDgAeQLrAIkLhmGqLwcjgO67tkoA3QT3cMBeD0nMskVxCDc8cvnSkvQ
