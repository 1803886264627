// @flow
import Cookies from "js-cookie";
import * as actionTypes from "./actions";
import { composeWithDevTools } from "redux-devtools-extension";
import { auth } from "../components/firebase/firebase";

const domain =
  process.env.NODE_ENV === "development" ? "localhost" : ".mudey.pt";

const token = Cookies.get("MUDEY_company_token") || null;
const uuid = Cookies.get("MUDEY_uuid") || null;
const email = Cookies.get("MUDEY_email") || null;
const name = Cookies.get("MUDEY_name") || null;

const user = token
  ? {
      email,
      name,
      uuid,
    }
  : null;

const initialState = {
  user: user,
  token: token,
  isAuthenticated: false,
  status: "out",
  comapanyMemberDetail: null,
};

const reducer = (state: Object = initialState, action: Object) => {
  const { payload, type, birthdate } = action;
  switch (type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case actionTypes.LOGOUT:
      auth.signOut();

      Cookies.remove('MUDEY_company_token')
      Cookies.remove('MUDEY_wallet_token')
      Cookies.remove('MUDEY_wallet_token')
      Cookies.remove('MUDEY_uuid')
      Cookies.remove('MUDEY_email')
      Cookies.remove('MUDEY_name')
      Cookies.remove('MUDEY_wallet_uuid')
      Cookies.remove('MUDEY_wallet_email')
      Cookies.remove('MUDEY_wallet_name')
      Cookies.remove('MUDEY_hard_expiration');
      return {
        ...state,
        comapanyMemberDetail: null,
        token: null,
        isAuthenticated: false,
        user: null,
        status: "out",
      };
    case actionTypes.COMPANY_MEMBER_DETAILS:
      return {
        ...state,
        comapanyMemberDetail: payload,
      };
    default:
      return state;
  }
};

export default reducer;
