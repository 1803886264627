import React from "react";

import roundTickGreen from "~images/mudey-green-round-tick.png";
import "./index.less";

const IframeRedirect = () => {
  return (
    <div className="iframe-redirect-success" >
      <img src={roundTickGreen} className="tickIcon" />
      <p className="heading">O documento está assinado.</p>
    </div>
  );
};

export default IframeRedirect;
