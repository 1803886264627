import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Intercom from "react-intercom";
import { Route, useHistory } from "react-router";
import NewFooter from "../footer/NewFooter";
import logoImg from "~images/mudeyEmpresasLogo_purple.svg";
import IframeRedirect from "../../containers/iframeRedirect";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PublicRoutes = (props: any) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  const history = useHistory();
  const user = useSelector((state: any) => state.comapanyMemberDetail);
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
  const listForHeaderFooter = [
    "WorkerThankYou",
    "WorkerForms",
    "WorkerConfirmation",
    "Sodexo",
    "HomePage",
    "SodexoProduct",
    "LifeProductInfoPage",
    "LifeQuestionnaire",
    "LifeSummary",
    "LifeThankYou",
    "LifeResults",
    "LifeConfirmation",
  ];
  useEffect(() => {
    if (
      isAuthenticated &&
      ![
        "WorkerThankYou",
        "WorkerForms",
        "WorkerConfirmation",
        "IframeRedirectSuccess",
      ].includes(props.name)
    ) {
      history.push("/dados-empresa");
    }
  }, []);

  return (
    <>
      {listForHeaderFooter.includes(props.name) ? (
        <div
          className="main-container"
          style={{ padding: 0 }}
          // style={{ width: "100%", minHeight: "1000px" }}
          // style={{ position: "relative" }}
        >
          <div style={{ minHeight: "1100px", paddingBottom: "4rem" }}>
            {![
              "/parceria-pluxee",
              "/parceria-pluxee/seguro-vida-empresas",
            ].includes(location?.pathname) && (
              <header className="header" style={{ marginBottom: "10px" }}>
                <div className="container">
                  <div className="header-wrapper">
                    <a href={process.env.REACT_APP_HP_URL}>
                      <img alt="Mudey" src={logoImg} title="Mudey" />
                    </a>
                  </div>
                </div>
              </header>
            )}
            <div style={{ paddingBottom: 60, height: "100%" }}>
              <Route {...props} />
            </div>
          </div>

          {/* {listForHeaderFooter.includes(props.name) && ( */}
          <NewFooter customCSS={{ display: "flex" }} />
          {/* )} */}
        </div>
      ) : props.name == "IframeRedirectSuccess" ? (
        <div className="main-container">
          <IframeRedirect />
        </div>
      ) : (
        <Route {...props} />
      )}
    </>
  );
};

export default PublicRoutes;
