// @flow
const titles = {
  "seguros-vida-details": "MUDEY Empresas | Seguro Vida Empresas | Detalhes",
  "dados-empresa": "MUDEY Empresas | Dados Empresa",
  "parceria-pluxee": {
    "seguro-vida-empresas": "MUDEY Empresas | Pluxee | Seguro Vida Grupo",
  },
  "parceria-sodexo": {
    "seguro-vida-empresas": "MUDEY Empresas | Pluxee | Seguro Vida Grupo",
  },
  entrar: "MUDEY | Wallet Empresa | Entrar",
  registar: "MUDEY | Wallet Empresa | Registar",
  restore: "MUDEY Empresas | Nova Password",
  settings: "MUDEY Empresas | Configurações",
  "recuperar-pass": "MUDEY Empresas | Recuperação Password",
  seguros: "MUDEY Empresas | Seguros",
  simulações: "MUDEY Empresas | Simulações",
  "seguros-details": "MUDEY Empresas | Detalhe Apólice",
  comprar: "MUDEY Empresas | Seleção Produto",
  "recuperar-nif": "MUDEY Empresas | Recuperação NIF",
  cyber: {
    summary: "Business Wallet | Compra",
    // resultados: "MUDEY Empresas | Cyber | Resultados",
    resultados: {
      summary: "MUDEY Empresas | Cyber | Pagamento",
      comparar: "MUDEY Empresas | Seguro Animais Domésticos | Comparação",
      pacotes: "MUDEY Empresas | Cyber | Resultados",
      "informação-adicional": "MUDEY Empresas | Cyber | Assinatura ",
    },
    compra: {
      pagamento: "MUDEY Empresas | Seguro Alojamento Local | Pagamento",
      obrigado: "MUDEY Empresas | Cyber | Confirmação",
      beneficiarios: "",
      "thank-you": "MUDEY Empresas | Seguro Alojamento Local | Pagamento",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
    // compra: "MUDEY Empresas | Cyber | Resultados",
    simulacao: "MUDEY Empresas | Cyber | Questionário",
    informação: "MUDEY Empresas | Cyber | Informação",
  },

  "seguro-vida-empresas": {
    informação: "MUDEY Empresas | Seguro Vida Grupo | Informação",
    summary: "Business Wallet | Compra",
    resultados: {
      summary: "MUDEY Empresas | Seguro Vida Grupo | Pagamento",
      comparar: "MUDEY Empresas | Seguro Vida Grupo | Comparação",
      pacotes: "MUDEY Empresas | Seguro Vida Grupo | Resultados",
      "informação-adicional":
        "MUDEY Empresas | Seguro de Vida Empresas | Assinatura",
    },
    compra: {
      pagamento: "MUDEY Empresas | Seguro Vida Grupo | Pagamento",
      obrigado: "MUDEY Empresas | Seguro Vida Grupo | Confirmação",
      beneficiarios: "",
      "thank-you": "MUDEY Empresas | Seguro Vida Grupo | Pagamento",
      "payment-failure": "",
      "confirmacao-compra": "",
    },
    simulacao: "MUDEY Empresas | Seguro Vida Grupo | Questionário",
    "pessoa-segura-info": {
      compra: "MUDEY Empresas | Seguro Vida Empresas | Info submetida",
      "informação-adicional":
        "MUDEY Empresas | Seguro de Vida Empresas | Assinatura",
    },
    "iframe-redirect-success":
      "MUDEY Empresa | Seguro Vida Empresas | Documento Assinado",
  },
  "adicionar-seguros": {
    "adicionar-seguro-at-empresas":
      "MUDEY Empresas | Adicionar | Seguro Acidentes Trabalho",
    "adicionar-seguro-vida-empresas":
      "MUDEY Empresas | Adicionar | Seguro Vida",
    "adicionar-seguro-saude-empresas":
      "MUDEY Empresas | Adicionar | Seguro Saude",
    "multirriscos-empresarial":
      "MUDEY Empresas | Adicionar | Seguro Multiriscos",
    "responsabilidade-civil-profissional":
      "MUDEY Empresas | Adicionar | Seguro Responsabilidade Civil",
  },
  "adicionar-seguros-at-detalhes":
    "MUDEY Empresas | Detalhes | Seguro Acidentes Trabalho",
  "adicionar-seguros-vida-detalhes": "MUDEY Empresas | Detalhes | Seguro Vida",
  "adicionar-seguros-saude-detalhes":
    "MUDEY Empresas | Detalhes | Seguro Saude",
  "adicionar-seguros-responsabilidade-civil-detalhes":
    "MUDEY Empresas | Detalhes | Seguro Responsabilidade Civil",
  "adicionar-seguros-multiriscos-detalhes":
    "MUDEY Empresas | Detalhes | Seguro Multiriscos",

  // "worker-forms": {
  //   simulacao: "MUDEY Empresas | Worker Forms",
  //   compra: {
  //     obrigado: "MUDEY Empresas | Worker Forms | Compra"
  //   }
  // },
};

export const titleManager = (location: any): void => {
  if (location && location.pathname) {
    const root = location.pathname.split("/")[1] || "";
    const firstKey = location.pathname.split("/")[2] || "";
    const lastKey = location.pathname.split("/")[3] || "";
    const title = () => {
      if (lastKey) {
        return titles[root][firstKey][lastKey] || "Business Wallet";
      }
      if (lastKey == "" && firstKey == "pessoa-segura-info") {
        return "MUDEY Empresas | Seguro Vida Empresas | Info adicional";
      }
      if (firstKey == "" && lastKey == "" && root == "parceria-pluxee") {
        return "MUDEY Empresas | Pluxee | Parceria";
      }
      if (firstKey == "" && lastKey == "" && root == "parceria-sodexo") {
        return "MUDEY Empresas | Pluxee | Parceria";
      }
      if (root == "adicionar-seguros" && firstKey == "" && lastKey == "") {
        return "MUDEY Empresas | Adicionar Seguros";
      }
      if (!lastKey && firstKey) {
        return titles[root][firstKey] || null;
      }
      if (root && firstKey.length == 0 && lastKey.length == 0) {
        return titles[root] || null;
      }
      return null;
    };

    const docTitle = title();
    if (docTitle) {
      document.title = docTitle;
    } else {
      document.title = "MUDEY Empresas | Homepage";
    }
  } else {
    document.title = "Business Wallet";
  }
};
