// Auth Routes (Login required)
import React from "react";

// Public Routes
const NotFoundPage = React.lazy(() =>
  import("../containers/state-page/not-found")
);
const AccessSettings = React.lazy(() =>
  import("../containers/accessSettings/index")
);
const HomePage = React.lazy(() => import("../containers/home/index"));
const Login = React.lazy(() => import("../containers/login/LoginPage"));
const SignUP = React.lazy(() => import("../containers/sign-up/SignUpPage"));
const ForgotPassword = React.lazy(() =>
  import("../containers/forgot/ForgotPassword")
);
const ForgotNIF = React.lazy(() => import("../containers/forgot/ForgotNIF"));
const ResetPassword = React.lazy(() =>
  import("../containers/resetPassword/ResetPassword")
);
const CompanyDetails = React.lazy(() =>
  import("../containers/companyDetails/index")
);

const Policies = React.lazy(() => import("../containers/Policies"));
const CyberPoliciesDetails = React.lazy(() =>
  import("../containers/Policies/policyDetails/cyber/policiesDetails")
);
const IframeRedirectSuccess = React.lazy(() =>
  import("../containers/iframeRedirect/index")
);
const LifePoliciesDetails = React.lazy(() =>
  import(
    "../containers/Policies/policyDetails/businessLife/lifePoliciesDetails"
  )
);
const Quotes = React.lazy(() => import("../containers/Quotes"));

const CyberProductInfoPage = React.lazy(() =>
  import("../containers/cyber/journey/productInfo")
);
const CyberQuestionnaire = React.lazy(() =>
  import("../containers/cyber/journey/index")
);
const CyberResults = React.lazy(() =>
  import("../containers/cyber/resultsNew/index")
);
const CyberSummary = React.lazy(() =>
  import("../containers/cyber/summary/CyberSummary")
);
const CyberThankYou = React.lazy(() =>
  import("../containers/thank-you/cyber/CyberThankYou")
);
const ComprarOnline = React.lazy(() => import("../containers/comprar/index"));

const LifeProductInfoPage = React.lazy(() =>
  import("../containers/journey/businessLife/productInfo")
);
const LifeQuestionnaire = React.lazy(() =>
  import("../containers/journey/businessLife/index")
);
const LifeSummary = React.lazy(() =>
  import("../containers/summary/life/LifeSummary")
);
const LifeConfirmation = React.lazy(() =>
  import("../containers/summary/life/components/confirmation/LifeConfirmation")
);

const CyberConfirmation = React.lazy(() =>
  import(
    "../containers/cyber/summary/components/confirmation/CyberConfirmation"
  )
);
const LifeThankYou = React.lazy(() =>
  import("../containers/thank-you/businessLife/BusinessLifeThankYou")
);
const LifeResults = React.lazy(() =>
  import("../containers/results/businessLife/index")
);

const WorkerForms = React.lazy(() =>
  import("../containers/workerForms/WorkerForm")
);
const WorkerThankYou = React.lazy(() =>
  import("../containers/workerForms/workerThankYou/WorkerThankYou")
);
const WorkerConfirmation = React.lazy(() =>
  import("../containers/workerForms/workerConfirmation/WorkerConfirmation")
);
const SodexoPage = React.lazy(() =>
  import("../containers/companyPage/sodexo/SodexoPage")
);
const SodexoProductPage = React.lazy(() =>
  import("../containers/companyPage/sodexo/product-page/SodexoProductPage")
);
const AddExtInsurance = React.lazy(() =>
  import("../containers/addExtInsurance")
);
const HealthPolicy = React.lazy(() =>
  import("../containers/addExtInsurance/extPolicies/health")
);
const LifePolicy = React.lazy(() =>
  import("../containers/addExtInsurance/extPolicies/life")
);
const WorkAccidentPolicy = React.lazy(() =>
  import("../containers/addExtInsurance/extPolicies/workAccident")
);
const HealthPolicyDetails = React.lazy(() =>
  import("../containers/Policies/extPolicyDetails/health")
);
const LifePolicyDetails = React.lazy(() =>
  import("../containers/Policies/extPolicyDetails/life")
);
const WorkAccidentPolicyDetails = React.lazy(() =>
  import("../containers/Policies/extPolicyDetails/workAccident")
);
const BuildingPolicyDetails = React.lazy(() =>
  import("../containers/Policies/extPolicyDetails/building")
);
const ProfessionalLiabilityPolicyDetails = React.lazy(() =>
  import("../containers/Policies/extPolicyDetails/professionalLiability")
);

const BuildingExternalPolicy = React.lazy(() =>
  import("../containers/addExtInsurance/extPolicies/building")
);
const ProfessionalLiability = React.lazy(() =>
  import("../containers/addExtInsurance/extPolicies/professionalLiability")
);

const ExternalPolicyDetails = React.lazy(() =>
  import("../containers/Policies/new-extPolicy/ExternalPolicy")
);

const routes = [
  {
    path: "/not-found",
    exact: true,
    name: "NotFoundPage",
    component: NotFoundPage,
    authRequired: false,
  },
  {
    path: "/",
    exact: true,
    name: "HomePage",
    component: HomePage,
    authRequired: false,
  },
  {
    path: "/entrar",
    exact: true,
    name: "Entrar",
    component: Login,
    authRequired: false,
  },
  {
    path: "/registar",
    exact: true,
    name: "Registar",
    component: SignUP,
    authRequired: false,
  },
  {
    path: "/dados-empresa",
    exact: true,
    name: "CompanyDetails",
    component: CompanyDetails,
    authRequired: true,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    component: AccessSettings,
    authRequired: true,
  },
  {
    path: "/recuperar-pass",
    exact: true,
    name: "Forgot",
    component: ForgotPassword,
    authRequired: false,
  },
  {
    path: "/recuperar-nif",
    exact: true,
    name: "Forgot NIF",
    component: ForgotNIF,
    authRequired: false,
  },
  {
    path: "/restore",
    exact: true,
    name: "Restore",
    component: ResetPassword,
    authRequired: false,
  },
  {
    path: "/cyber/simulacao",
    exact: true,
    name: "CyberQuestionnaire",
    component: CyberQuestionnaire,
    authRequired: true,
    notAccess: ["Sodexo"],
  },
  {
    path: "/cyber/resultados/pacotes",
    exact: true,
    name: "CyberResults",
    component: CyberResults,
    authRequired: true,
    notAccess: ["Sodexo"],
  },
  {
    path: "/cyber/resultados/summary/:orderId",
    exact: true,
    name: "CyberSummary",
    component: CyberSummary,
    authRequired: true,
    notAccess: ["Sodexo"],
  },
  {
    path: "/cyber/compra/obrigado/:orderId?",
    exact: true,
    name: "CyberThankYou",
    component: CyberThankYou,
    authRequired: true,
    notAccess: ["Sodexo"],
  },
  {
    path: "/seguros",
    exact: true,
    name: "Policies",
    component: Policies,
    authRequired: true,
  },
  {
    path: "/seguros-details/:policyId?",
    exact: true,
    name: "CyberPoliciesDetails",
    component: CyberPoliciesDetails,
    authRequired: true,
  },
  {
    path: "/simulações",
    exact: true,
    name: "simulações",
    component: Quotes,
    authRequired: true,
  },
  {
    path: "/comprar",
    exact: true,
    name: "ComprarOnline",
    component: ComprarOnline,
    authRequired: true,
  },
  {
    path: "/cyber/resultados/informação-adicional/:orderId?",
    exact: true,
    name: "CyberConfirmation",
    component: CyberConfirmation,
    authRequired: true,
  },
  {
    path: "/cyber/informação",
    exact: true,
    name: "CyberProductInfoPage",
    component: CyberProductInfoPage,
    authRequired: true,
  },

  //LIFE JOURNEY
  {
    path: "/seguro-vida-empresas/informação",
    exact: true,
    name: "LifeProductInfoPage",
    component: LifeProductInfoPage,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/simulacao/:journeyId?",
    exact: true,
    name: "LifeQuestionnaire",
    component: LifeQuestionnaire,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/resultados/summary/:orderId?",
    exact: true,
    name: "LifeSummary",
    component: LifeSummary,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/compra/obrigado/:orderId?",
    exact: true,
    name: "LifeThankYou",
    component: LifeThankYou,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/resultados/pacotes/:journeyId?",
    exact: true,
    name: "LifeResults",
    component: LifeResults,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/resultados/informação-adicional/:orderId?",
    exact: true,
    name: "LifeConfirmation",
    component: LifeConfirmation,
    authRequired: false,
  },

  {
    path: "/seguro-vida-empresas/pessoa-segura-info",
    exact: true,
    name: "WorkerForms",
    component: WorkerForms,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/pessoa-segura-info/compra/obrigado",
    exact: true,
    name: "WorkerThankYou",
    component: WorkerThankYou,
    authRequired: false,
  },
  {
    path: "/seguro-vida-empresas/pessoa-segura-info/informação-adicional",
    exact: true,
    name: "WorkerConfirmation",
    component: WorkerConfirmation,
    authRequired: false,
  },
  {
    path: "/seguros-vida-details/:policyId?",
    exact: true,
    name: "LifePoliciesDetails",
    component: LifePoliciesDetails,
    authRequired: true,
  },
  {
    path: "/seguro-vida-empresas/iframe-redirect-success",
    exact: true,
    name: "IframeRedirectSuccess",
    component: IframeRedirectSuccess,
    authRequired: false,
  },
  {
    path: "/parceria-pluxee",
    exact: true,
    name: "Sodexo",
    component: SodexoPage,
    authRequired: false,
  },
  {
    path: "/parceria-pluxee/seguro-vida-empresas",
    exact: true,
    name: "SodexoProduct",
    component: SodexoProductPage,
    authRequired: false,
  },
  {
    path: "/adicionar-seguros",
    exact: true,
    name: "AddExtInsurance",
    component: AddExtInsurance,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros/adicionar-seguro-saude-empresas",
    exact: true,
    name: "HealthPolicy",
    component: HealthPolicy,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros/adicionar-seguro-vida-empresas",
    exact: true,
    name: "LifePolicy",
    component: LifePolicy,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros/adicionar-seguro-at-empresas",
    exact: true,
    name: "WorkAccidentPolicy",
    component: WorkAccidentPolicy,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros/multirriscos-empresarial",
    exact: true,
    name: "BuildingExternalPolicy",
    component: BuildingExternalPolicy,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros/responsabilidade-civil-profissional",
    exact: true,
    name: "ProfessionalLiability",
    component: ProfessionalLiability,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros-saude-detalhes/:policyId?",
    exact: true,
    name: "HealthPolicyDetails",
    // component: HealthPolicyDetails,
    component: ExternalPolicyDetails,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros-vida-detalhes/:policyId?",
    exact: true,
    name: "LifePolicyDetails",
    // component: LifePolicyDetails,
    component: ExternalPolicyDetails,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros-at-detalhes/:policyId?",
    exact: true,
    name: "WorkAccidentPolicyDetails",
    // component: WorkAccidentPolicyDetails,
    component: ExternalPolicyDetails,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros-multiriscos-detalhes/:policyId?",
    exact: true,
    name: "BuildingPolicyDetails",
    // component: BuildingPolicyDetails,
    component: ExternalPolicyDetails,
    authRequired: true,
  },
  {
    path: "/adicionar-seguros-responsabilidade-civil-detalhes/:policyId?",
    exact: true,
    name: "ProfessionalLiabilityPolicyDetails",
    // component: ProfessionalLiabilityPolicyDetails,
    component: ExternalPolicyDetails,
    authRequired: true,
  },
];

export default routes;
