// @flow

import React, { useState, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router";
import { useSelector } from "react-redux";
import Intercom from "react-intercom";
import NewFooter from "../footer/NewFooter";
import { checkCookies } from "~utils/checkCookies";
import SideBar from "../sidebar";

const AuthRoute = (props: any) => {
  const user = useSelector((state: any) => state.comapanyMemberDetail);
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
  const history = useHistory();
  const { path, title, notAccess } = props;
  const isAuthenticated = useSelector((state: any) => state.isAuthenticated);
  const companyId = checkCookies();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    if (notAccess && companyId) {
      if (notAccess.includes(companyId)) {
        history.push("/dados-empresa");
      }
    }
  }, [companyId, notAccess]);

  return (
    <div className="main-container">
      <SideBar />
      {isAuthenticated ? (
        <Route {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/entrar",
            state: { from: path },
          }}
        />
      )}
      <NewFooter />
      {/* <Intercom appID={intercomAppId} {...user} /> */}
    </div>
  );
};

export default AuthRoute;
