/* eslint-disable flowtype/no-types-missing-file-annotation */
import { ApolloLink } from "apollo-link";
import { split, HttpLink, InMemoryCache, ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

import Cookies from "js-cookie";
import moment from "moment";

import { auth } from "~components/firebase/firebase";
import { store } from "../store";
import { LOGOUT } from "../store/actions";

const env = process.env.NODE_ENV;
const domain = env === "development" ? "localhost" : ".mudey.pt";
const url = process.env.REACT_APP_API_URL;
const wsURL =
  env === "development"
    ? "wss://staging-api.mudey.pt/subscriptions"
    : process.env.REACT_APP_WSS_URL;
const httpLink = new HttpLink({
  uri: url,
  credentials: "include",
});

const authLink = setContext((_: any, { headers }: any) => {
  if (auth.currentUser) {
    const expirationHardLimit = Cookies.get("MUDEY_hard_expiration");
    // ! clean up

    const expiresAt = auth.currentUser.stsTokenManager.expirationTime;
    const sessionExpired =
      moment().isAfter(moment(expiresAt)) ||
      moment().isAfter(moment(expirationHardLimit));
    const lessThenFourtyMinutes = moment().isAfter(
      moment(expiresAt).subtract(30, "minutes")
    );

    if (lessThenFourtyMinutes && !sessionExpired) {
      auth.currentUser
        .getIdToken(true)
        .then(function(idToken: any) {
          // ! clean up

          Cookies.set("MUDEY_company_token", idToken);
        })
        .catch(function(error: any) {
          console.log("error: ", error);
        });
    }

    if (sessionExpired) {
      // ! clean up

      store.dispatch({ type: LOGOUT });
    }
  }

  const token = Cookies.get("MUDEY_company_token") || "insta-checkout";

  Cookies.set("MUDEY_BUSINESS_FLAG", true);

  return {
    headers: {
      ...headers,
      MUDEY_AUTH_TOKEN: token,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: wsURL,
  options: {
    //lazy: true,
    //reconnect: true,
    connectionParams: async () => {
      const app_token = await Cookies.get("MUDEY_company_token");

      return {
        credentials: "include",
        MUDEY_AUTH_TOKEN: app_token,
        Authorization: "Basic bXVkZXlkZXZhcGk6aXRzdGltZQ==",
      };
    },
  },
});

const link = split(
  ({ query }: any) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([link]),
});

export default client;
