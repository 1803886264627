import React, { useState, useEffect } from "react";
import {
  Link,
  Redirect,
  NavLink,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import { auth } from "~components/firebase/firebase";
import Routes from "../../routes";
import { LOGOUT } from "../../store/actions";

import mudeyLogo_white from "~images/mudeyEmpresasLogo_white.svg";

import "./index.less";

const SideBar = () => {
  const dispatch = useDispatch();
  const [mobileMenu, setMobileMenu] = useState(false);

  // ! clean up

  const [requestedPath, setRequestedPath] = useState(useLocation().pathname);
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState({ status: "loading" });
  const domain =
    process.env.NODE_ENV === "development" ? "localhost" : ".mudey.pt";
  return (
    <div className={"side-menu " + (mobileMenu ? "" : "hidden-list")}>
      <div className="logo-wrapper-sidebar">
        <img src={mudeyLogo_white} className="logo-sidebar" />
      </div>
      <img
        alt=""
        className="menu-trigger"
        onClick={() => {
          setMobileMenu(!mobileMenu);
        }}
        src={require("../../images/icons/bars.svg")}
      />
      <a href={process.env.REACT_APP_HP_URL}>
        <div className="logo_micro" />
      </a>
      <div
        className="logo"
        style={{ backgroundImage: `url(${mudeyLogo_white})` }}
      />
      <div className={"navigation " + (mobileMenu ? "" : "hidden-sm")}>
        <ul>
          <li
          //  className={requestedPath != "" ? "active" : ""}
          >
            <NavLink
              to="/dados-empresa"
              className="nav-links-inactive"
              activeClassName="nav-links-active"
              // className={(isActive: any) =>
              //   "nav-link" + (!isActive ? " unselected" : "")
              // }
              onClick={() => {
                setMobileMenu(!mobileMenu);
                window.scrollTo(0, 0);
              }}
            >
              {/* <img alt="" src={require("../../images/icons/icon5.svg")} /> */}
              <img alt="" src={require("../../images/icons/icon5u.svg")} />
              <span style={{ fontFamily: "Brandon Grotesque Regular" }}>
                Dados da Empresa
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/simulações"
              className="nav-links-inactive"
              activeClassName="nav-links-active"
              onClick={() => {
                setMobileMenu(!mobileMenu);
                window.scrollTo(0, 0);
              }}
            >
              <img alt="" src={require("../../images/icons/icon6u.svg")} />
              <span style={{ fontFamily: "Brandon Grotesque Regular" }}>
                Simulações em aberto
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/seguros"
              className="nav-links-inactive"
              activeClassName="nav-links-active"
              onClick={() => {
                setMobileMenu(!mobileMenu);
                window.scrollTo(0, 0);
              }}
            >
              <img alt="" src={require("../../images/icons/icon1u.svg")} />
              <span style={{ fontFamily: "Brandon Grotesque Regular" }}>
                Seguros em vigor
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings"
              className="nav-links-inactive"
              activeClassName="nav-links-active"
              // className={(isActive: any) =>
              //   "nav-link" + (!isActive ? " unselected" : "")
              // }
              // to={""}
              onClick={() => {
                setMobileMenu(!mobileMenu);
                window.scrollTo(0, 0);
              }}
            >
              {/* <img alt="" src={require("../../images/icons/icon2.svg")} /> */}
              <img alt="" src={require("../../images/icons/icon2u.svg")} />
              <span style={{ fontFamily: "Brandon Grotesque Regular" }}>
                Configurações
              </span>
            </NavLink>
          </li>

          <li className="comprar-online">
            <NavLink
              to="/comprar"
              className="nav-links-active"
              activeClassName="nav-links-active"
              onClick={() => {
                setMobileMenu(!mobileMenu);
                window.scrollTo(0, 0);
              }}
            >
              <img alt="" src={require("../../images/icons/buy.svg")} />
              <span style={{ fontFamily: "Brandon Grotesque Regular" }}>
                Comprar Seguros
              </span>
            </NavLink>
          </li>

          <li className="comprar-online external-comprar-online">
            <NavLink
              to="/adicionar-seguros"
              className="nav-links-active"
              activeClassName="nav-links-active"
              onClick={() => {
                setMobileMenu(!mobileMenu);
                window.scrollTo(0, 0);
              }}
            >
              <img alt="" src={require("../../images/icons/buy.svg")} />
              <span style={{ fontFamily: "Brandon Grotesque Regular" }}>
                Adicionar seguros
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
      <div
        className={"logout " + (mobileMenu ? "" : "hidden-sm")}
        onClick={() => {
          dispatch({ type: LOGOUT });
        }}
      >
        <img alt="" src={require("../../images/icons/logout.svg")} />{" "}
        <span style={{ fontFamily: "Brandon Grotesque Regular" }}>Sair</span>
      </div>
    </div>
  );
};

export default SideBar;
